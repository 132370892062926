<template>
  <div>
    <b-modal id="dialog_order_comment" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-book"></i>ข้อมูลรายละเอียดเพิ่มเติม</h3>
      </template>
      <div>
        <div class="mb-4 row">
          <div class="col-sm-12">
            <b-form-textarea name="caption" placeholder="รายละเอียดเพิ่มเติม" v-model="comment" rows="3" max-rows="5"></b-form-textarea>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="save_comment()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name : "dialog_order_comment",
  data () {
    return {
      order_id : null,
      comment : ""
    }
  },
  methods: {
    async load_order(order_id){
      this.order_id = order_id;
      const result = await this.HttpServices.getData(`/ab_order/load_comment/${order_id}`);
      if (result && result.status.code == "200") {
        this.comment = result.data.comment;
        console.log(result)
      }
    },async save_comment(){
      const params = { comment: this.comment};
      const result = await this.HttpServices.putData(`/ab_order/save_comment/${this.order_id}`, params);
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", "แก้ไขข้อความสำเร็จ");
      this.$bvModal.hide('dialog_order_comment');
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }

      if (result && result.status.code == "200") {
        this.comment = result.data.comment;
        console.log(result)
      }
    },
    clickAgree () {
      console.log(this.item);
      this.$emit('selected', this.item);
      this.$bvModal.hide('dialog_order_comment');
    },
    close () {
      this.$bvModal.hide('dialog_order_comment');
    }
  }
}
</script>
<style scoped>
</style>
