<template>
  <b-modal id="dialog_order_cancel">
    <template #modal-title>
      <h3>ยกเลิก order</h3>
    </template>
    <div>
      ต้องการที่จะยกเลิก order ดังต่อไปนี้ ?
      <ul>
        <template v-for="(data, index) in item">
          <li v-if="data.selected" :key="index">{{data.code}}</li>
        </template>
      </ul>
    </div>
    <div>
      เหตุผลที่ต้องการยกเลิก ?
     <select v-model="selected_reason" class="form-control" >
      <option
        v-for="item_cancel in cancel_items"
        :key="`cbx_cancel_${item_cancel.id}`"
        :value="item_cancel.id"
      >
        {{ item_cancel.name }}
      </option>
    </select>
    </div>
    <div>
      หมายเหตุ
      <textarea class="form-control" v-model="cancel_remark"></textarea>
    
    </div>
    <template #modal-footer>
      <b-button variant="danger" @click="removeHandler()">ตกลง</b-button>
      <b-button variant="secondary" @click="close()">ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    data() {
      return {
        selected_reason : "1",
        cancel_items : [],
        cancel_remark: "",
        show: true
      }
    },
    props: [
      'item', 'successFuction'
    ],
    methods: {
      async removeHandler () {
        const selected = [];
        for(const v of this.item){
          if(v.selected){
            selected.push(v.id);
          }
        }
        if (selected === 0) {
          this.AlertUtils.alert('warning', `กรุณาเลือกข้อมูลที่ต้องการยกเลิก`);
          this.$bvModal.hide('dialog_order_cancel');
          return;
        }
        const result = await this.HttpServices.postData("/ab_order/cancelOrderStatus", {
          id: JSON.stringify(selected),
          selected:this.selected_reason,
          cancel_remark:this.cancel_remark
        });
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_order_cancel');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_order_cancel');
      },
      async getCancelList () {
        const result = await this.HttpServices.getData(`/ab_cancel_order/`);
        
        if (result.status.code == 200 && result.data.data.length > 0) {
          this.cancel_items = result.data.data;
        }
      }
    },
    mounted() {
       this.getCancelList();
    }
  }
</script>
