<template>
  <div>
    <b-modal id="dialog_order_review" size="xl">
      <template #modal-title>
        <h3> ข้อมูลใบสั่งซื้อ</h3>
      </template>
      <div>
        <div>
          <div>ชื่อผู้สั่ง: {{customer&&customer.contact?customer.contact:'-'}}</div>
          <div>สถานที่จัดส่ง : {{item&&item.delivery_location?item.delivery_location:'-'}}</div>
          <div>ที่อยู่ผู้รับ : {{item&&item.delivery_address?item.delivery_address:'-'}}</div>
          <div>***ข้อเน้นย้ำ {{item.remark}}</div>
        </div>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="ข้อมูลรายการสั่งซื้อ" active>
              <div>คำสั่งซื้อ : {{item&&item.code?item.code:'-'}}</div>
              <div>วันที่สั่งซื้อ : {{order_info.date_order}}</div>
              <div>วันที่จัดส่ง : {{item&&item.delivery_date?item.delivery_date:'-'}} {{item&&item.delivery_time?item.delivery_time:'-'}}</div>
              <div>ร้านค้า : {{item&&item.store?item.store.name:'-'}}</div>
              <div>สถานที่จัดส่ง : {{item&&item.delivery_location?item.delivery_location:'-'}}</div>
              <div>Supplier : {{suppliers&&suppliers.name?suppliers.name:'-'}}</div>
              <div>ชื่อลูกค้า : {{item&&item.customer_name?item.customer_name:'-'}}</div>
              <div>ชื่อผู้รับ : {{item&&item.delivery_contact?item.delivery_contact:'-'}}</div>
              <div>เบอร์โทรศัพท์ : {{item&&item.delivery_phone?item.delivery_phone:'-'}}</div>
              <div><hr></div>
              <div>Company Name : {{item&&item.company_name?item.company_name:'-'}}</div>
              <div>Billing Address : {{item&&item.billing_address?item.billing_address:'-'}}</div>
              <div>TaxID : {{item&&item.tax_id?item.tax_id:'-'}}</div>
              <div><hr></div>
              <div>Tracking ID : {{item&&item.tracking_id?item.tracking_id:'-'}}</div>
            </b-tab>
            <b-tab title="ข้อมูลสินค้า">
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                    <tr class="text-center" >
                      <th>#</th>
                      <th>รหัสสินค้า</th>
                      <th style="min-width: 330px; width: 1px;">ชื่อสินค้า</th>
                      <th style="min-width: 330px; width: 1px;">รูปภาพ</th>
                      <th style="min-width: 120px; width: 1px;">ราคา</th>
                      <th style="min-width: 200px; width: 1px;">รายละเอียดเพิ่มเติม</th>
                      <th style="min-width: 200px; width: 1px;">แท็ก</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(product, index) in products">
                      <tr :key="`confirm_order_product_${index}`">
                        <td class="text-center" :rowspan="product.products.length+1">
                          {{index+1}}
                        </td>
                        <td>{{product.code}}</td>
                        <td>{{product.name}}</td>
                        <td>
                            <div v-for="img in product.imgs" :key="img.id">
                              <a :href="img.url" target="_blank"><img :src="img.url_t"></a>
                            </div>
                        </td>
                        <td class="text-right">{{product.price | number-decimal-format}}</td>
                        <td>
                          <div>ชื่อผู้รับ: {{product.recipient}}</div>
                          <div>ข้อความป้าย: บรรทัด 1: {{product.msg_fr}}<br><div>บรรทัด 2: {{product.msg_se}}</div></div>
                          <div>รายละเอียด: {{product.caption}}</div>
                          <div class="pt-5">
                            <img :src="!product.logoImg?noImageData:product.logoImg" class="img-thumbnail"
                                  style="max-width: 180px;">
                          </div>
                        </td>
                      </tr>
                      <tr v-for="(d, i) in product.products" :key="`confirm_order_product_sub_${i}`">
                        <td>{{d.code}}</td>
                        <td>{{d.name}}</td>
                        <td>
                            <div v-for="img in d.imgs" :key="img.id">
                              <a :href="img.url" target="_blank"><img :src="img.url_t"></a>
                            </div>
                        </td>
                        <td class="text-right">{{d.price | number-decimal-format}}</td>
                        <td>
                          <div>ชื่อผู้รับ: {{d.recipient}}</div>
                          <div>ข้อความป้าย: บรรทัด 1: {{d.msg_fr}}<br><div>บรรทัด 2: {{d.msg_se}}</div></div>
                          <div>รายละเอียด: {{d.caption}}</div>
                          <div>สี: {{d.color}}</div>
                          
                        </td>
                        <td>
                          <div v-for="tag in d.tags" :key="tag.key">
                            <span>{{tag.key}}</span>
                            <span>{{tag.value}}</span>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr class="text-center" v-if="products.length === 0">
                      <td colspan="4">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab title="ข้อมูลสำหรับคัดลอก">
              <div class="row p-4">
                  <div class="col-12">
                    <label>For Customer</label>
                    <textarea rows="10" class="form-control" v-model="templateCustomer"></textarea>
                  </div>
                  <div class="col-12">
                    <label>For Supplier</label>
                    <textarea rows="10" class="form-control" v-model="templateSupplier"></textarea>
                  </div>
                  <div class="col-12">
                    <label>For Billing</label>
                    <textarea rows="10" class="form-control" v-model="templateBill"></textarea>
                  </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        
        <div style="padding: 20px; display: none;" class="message_customer">
          {{templateCustomer}}
        </div>
        <textarea id="msgCustomer" readonly :value="templateCustomer" style="display:none"></textarea>

        <textarea id="msgSupplier" readonly :value="templateSupplier" style="display:none"></textarea>
        <div style="padding: 20px; display: none;" class="message_supplier">
          {{templateSupplier}}
        </div>

        <textarea id="msgBill" readonly :value="templateBill" style="display:none"></textarea>
        <div style="padding: 20px; display: none;" class="message_bill">
          {{templateBill}}
        </div>
      </div>
      <template #modal-footer>
        <b-button pill variant="info" @click="copyToClipboardCustomer" >คัดลอกสำหรับลูกค้า</b-button>
        <b-button pill variant="info" @click="copyToClipboardSupplier" >คัดลอกสำหรับซัพพลายเออร์</b-button>
        <b-button pill variant="info" @click="copyToClipboardBill" >คัดลอกสำหรับออกบิล</b-button>

        <b-button variant="primary" v-if="fConfirmable==true" @click="clickAgree()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import $ from 'jquery';
  import config from '../../../util/config';
  import Constants from '../../../util/constants';
  import DateUtils from '../../../util/dateUtils';
  import NumberUtils from '../../../util/numberUtils';
  export default {
    name: 'order-view-dialog-review',
    data() {
      return {
        fConfirmable : false,
        noImageData: Constants.noImageData,
        slide: 0,
        sliding: false,
        apiUrl: config.apiUrl,
        customerTypes: Constants.customer_types,
        billingTypes: Constants.bill_types,
        templateCustomer: '',
        templateSupplier: '',
        templateBill: '',
        order_info: {date_order:'-'}
      }
    },
    props: ['item', 'products', 'suppliers', 'supplier', 'customer'],
    methods: {
      close () {
        this.$bvModal.hide(`dialog_confirmed_order`);
      },
      clickAgree () {
        this.$emit('confirmed-order', 1);
        this.$bvModal.hide(`dialog_confirmed_order`);
      },
      copyToClipboardCustomer(element) {
        let newClipTextBill = $(`.message_customer`).text();
        let str = `${newClipTextBill}`;
        let testingCodeToCopy = document.querySelector('#msgCustomer');
        testingCodeToCopy.style = "display: block";
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
          
        }

        testingCodeToCopy.style = "display: none";
        window.getSelection().removeAllRanges();
      },
      copyToClipboardSupplier(element) {
        // let newClipText = $(`.order_products`).text();
        // console.log('newClipText ', newClipText, $(`.table_product`).text())
        // let newClipImgs = $(`.images_product`).attr('scr');

        let newClipTextBill = $(`.message_supplier`).text();
        let str = `${newClipTextBill}`;
        let testingCodeToCopy = document.querySelector('#msgSupplier');
        testingCodeToCopy.style = "display: block";
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
        }

        testingCodeToCopy.style = "display: none";
        window.getSelection().removeAllRanges();
      },
      copyToClipboardBill(element) {
        // let newClipText = $(`.order_products`).text();
        // console.log('newClipText ', newClipText, $(`.table_product`).text())
        // let newClipImgs = $(`.images_product`).attr('scr');

        let newClipTextBill = $(`.message_bill`).text();
        let str = `${newClipTextBill}`;
        let testingCodeToCopy = document.querySelector('#msgBill');
        testingCodeToCopy.style = "display: block";
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
        }

        testingCodeToCopy.style = "display: none";
        window.getSelection().removeAllRanges();
      },
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      setClipboard(text) {
        var type = "image/png";
        var blob = new Blob([text], { type });
        var data = [new ClipboardItem({ [type]: blob })];

        navigator.clipboard.write(data).then(
            function () {
            /* success */
              console.log('success')
            },
            function () {
            /* failure */
                console.log('error')
            }
        );
      },
      msg_product_tag_code_price(product){
        let s = '';
        s += 'สั่ง ';
        if(product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภท"){
              s += tag.value;
            }
          }
        s += ' รหัส: ';
        s += product.product_code;
        s += ' ราคา: ';
        s += NumberUtils.toDecimalFormat(product.price);
        s += ' บาท';
        return s;
      },
      msg_product_tag_code_cost(product){
        let s = '';
        s += 'สั่ง ';
        if(product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภท"){
              s += tag.value;
            }
          }
        s += ' รหัส: ';
        s += product.product_code;
        s += ' ราคา: ';
        s += NumberUtils.toDecimalFormat(product.cost);
        s += ' บาท';
        return s;
      },
      msg_product_detail(product){
        let s = '';
        s += '          ';
        s += '[';
        if(product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภท"){
              s += tag.value;
            }
          }
        s += ' รหัส: ';
        s += product.product_code;
       
        
        s += ' ]\n';
        s += '                    ';
        s += 'ข้อความ 1 :';
        s += product.msg_fr;
        s += '\n';
        s += '                    ';
        s += 'ข้อความ 2 :';
        s += product.msg_se;
        s += '\n';
        s += '                    ';
        s += 'ข้อเน้นย้ำ :';
        s += product.caption;
        s += '\n';
        s += '                    ';
        s += 'สี :';
        s += product.color;
        s += '\n';
        s += '                    ';
        s += 'ชื่อผู้รับ(ดอกไม้)/ชื่อผู้เสียชีวิต(พวงหรีด) :';
        s += product.recipient;
        s += '\n\n';
        return s;
      },

      async getItem(){
        console.log(this.item);
        console.log(this.item.store_id);
        const result = await this.HttpServices.getData(`/ab_template/getbystoreid/${this.item.store_id}`); 
        //const result = await this.HttpServices.getData(`/ab_template`);
        if(result&&result.status.code=="200"){

          if(this.item.fConfirmable){
            this.fConfirmable = this.item.fConfirmable;
          }

          const data = result.data;
          this.item.delivery_date = DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY");
          this.item.delivery_time = DateUtils.dateFormat(this.item.expected_delivery_time, "HH:mm");

          this.order_info.date_order = DateUtils.dateFormat(this.item.date_order, "DD/MM/YYYY")
          //this.item.date_order = DateUtils.dateFormat(this.item.date_order, "DD/MM/YYYY");
          

          let product = this.products[0];
          let product_children = '';
          let product_children_detail = '';
          let product_children_cost = '';
          let product_children_total_price = 0;
          let product_children_total_cost = 0;

          try{
            this.products[0].imgs = JSON.parse(this.products[0].imgs);
          }catch{
            this.products[0].imgs = [];
          }

          if(this.products[0].products.length > 0){
            for(let i in this.products[0].products){

              try{
                this.products[0].products[i].imgs = JSON.parse(this.products[0].products[i].imgs);
              }catch{
                this.products[0].products[i].imgs = [];
              }
              
              
              
              if(this.products[0].products[i].sub_cat==0){
                product = this.products[0].products[i];
              }else{
                product_children += '                    - '
                product_children += ' '+this.products[0].products[i].product_name;
                product_children += ' '+NumberUtils.toDecimalFormat(this.products[0].products[i].price) + ' บาท';
                product_children += '\n';
                product_children_total_price += this.products[0].products[i].price;
                
                product_children_cost += '                    - '
                product_children_cost += ' '+this.products[0].products[i].product_name;
                product_children_cost += ' '+NumberUtils.toDecimalFormat(this.products[0].products[i].cost) + ' บาท';
                product_children_cost += '\n';
                product_children_total_cost += this.products[0].products[i].cost;
                
                product_children_detail += this.msg_product_tag_code_price(this.products[0].products[i]);
              }
            }
          }

          let product_set = '';
          let product_set_detail = '';
          let product_set_supplier = '';
          let product_set_supplier_detail = '';
         

        
          for(let i in this.products){
            product_set += '          ';
            product_set += this.msg_product_tag_code_price(this.products[i]);
            product_set += '\n';
            product_set_supplier += '          ';
            product_set_supplier += this.msg_product_tag_code_cost(this.products[i]);
            product_set_supplier += '\n';

            if(this.products[i].products.length > 0){
              for(let j in this.products[i].products){
                  product_set += '                    - ';
                  product_set += ' '+this.products[i].products[j].product_name;
                  product_set += ' '+NumberUtils.toDecimalFormat(this.products[i].products[j].price) + ' บาท';
                  product_set += '\n';
                  
                  product_set_supplier += '                    - ';
                  product_set_supplier += ' '+this.products[i].products[j].product_name;
                  product_set_supplier += ' '+NumberUtils.toDecimalFormat(this.products[i].products[j].cost) + ' บาท';
                  product_set_supplier += '\n';
                }
              }
              product_set_detail += this.msg_product_detail(this.products[i]);
            }


          var data_x =  [
            {
              "id": 1,
              "name": "template_customer",
              "value": data.copy_customer
            },{
              "id": 2,
              "name": "template_supplier",
              "value": data.copy_supplier
            },{
              "id": 3,
              "name": "template_bill",
              "value": data.copy_order
            }
          ];


          for(const v of data_x){
            let text = v.value;
            let images = '';
            if(product&&product.imageDisplays){
              if(product.imageDisplays){
                for(const vv of product.imageDisplays){
                  if(images){
                    images += '\n';
                  }
                  images += vv;
                }
              }
            }

            let cusType = this.customerTypes.find(v=>v.id==this.customer.customer_type);
            if(cusType){
              cusType = cusType.text;
            }

          
            let billingType = this.billingTypes.find(v=>v.id==this.item.status_receipt);
            if(billingType){
              billingType = billingType.text;
            }

            

            let deliveryLocation = '';
            if(this.item.delivery_location){
              deliveryLocation += this.item.delivery_location + ' ';
            }
            if(this.item.delivery_address){
              deliveryLocation += this.item.delivery_address;
            }
            
            text = text.replaceAll("[product_set]", product_set);
            text = text.replaceAll("[product_set_detail]", product_set_detail);
            text = text.replaceAll("[product_set_supplier]", product_set_supplier);
            text = text.replaceAll("[product_set_supplier_detail]", product_set_supplier_detail);


            text = text.replaceAll("[store_name]", this.item.store?this.item.store.name?this.item.store.name:'':'');
            text = text.replaceAll("[product_children]", product_children);
            text = text.replaceAll("[product_children_cost]", product_children_cost);
            text = text.replaceAll("[product_children_detail]", product_children_detail);
            text = text.replaceAll("[product_code]", product&&product.product_code?product.product_code:'');
            text = text.replaceAll("[order_contact]", this.item.customer_contact?this.item.customer_contact:'');
            text = text.replaceAll("[location_name]", deliveryLocation);
            text = text.replaceAll("[delivery_location]", deliveryLocation);
            text = text.replaceAll("[caption]", this.item.caption?this.item.caption:'');
            text = text.replaceAll("[recipient_name]", product&&product.recipient?product.recipient:'');
            text = text.replaceAll("[msg_fr]", product&&product.msg_fr?product.msg_fr:'');
            text = text.replaceAll("[msg_se]", product&&product.msg_se?product.msg_se:'');
            text = text.replaceAll("[product_remark]", product&&product.msg_se?product.remark:'');
            text = text.replaceAll("[product_caption]", product&&product.caption?product.caption:'');
            text = text.replaceAll("[delivery_date]", DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY"));
            text = text.replaceAll("[delivery_time]", DateUtils.dateFormat(this.item.expected_delivery_time, "HH:mm"));
            text = text.replaceAll("[images]", images);//Array
            text = text.replaceAll("[bill_type]", billingType);
            text = text.replaceAll("[bill_name]", this.customer?this.customer.contact?this.customer.contact:'':'');
            text = text.replaceAll("[address]", this.item.supplier?this.item.supplier.tax_address?this.item.supplier.tax_address:'':'');
            text = text.replaceAll("[tax_id]", this.customer?this.customer.tax_id?this.customer.tax_id:'':'');
            text = text.replaceAll("[location_bill]", this.customer?this.customer.billing_address?this.customer.billing_address:'':'');
            text = text.replaceAll("[bill_location]", this.customer?this.customer.billing_address?this.customer.billing_address:'':'');
            text = text.replaceAll("[product_type]", "");
            text = text.replaceAll("[delivery_sender_name]", "");
            text = text.replaceAll("[delivery_name]", this.item.delivery_contact?this.item.delivery_contact?this.item.delivery_contact:'':'');
            text = text.replaceAll("[product_price]", product&&product.price?NumberUtils.toDecimalFormat(product.price):'-');
            text = text.replaceAll("[product_cost]", product&&product.cost?NumberUtils.toDecimalFormat(product.cost):'-');
            text = text.replaceAll("[wholesale_price_store]", product&&product.cost?NumberUtils.toDecimalFormat(product.cost):'-');
            text = text.replaceAll("[upsale_price]", "");
            text = text.replaceAll("[car_price]", this.item.service?NumberUtils.toDecimalFormat(this.item.service):'-');

            text = text.replaceAll("[total]", this.item.total?NumberUtils.toDecimalFormat(product.price+product_children_total_price+this.item.price_shipping):'-');
            text = text.replaceAll("[total_cost]", this.item.total?NumberUtils.toDecimalFormat(product.cost+product_children_total_cost+this.item.cost_shipping):'-');
            text = text.replaceAll("[order_phone]", this.customer.phone?this.customer.phone:'');
            text = text.replaceAll("[delivery_contact]", this.item.delivery_contact?this.item.delivery_contact:'');
            text = text.replaceAll("[delivery_phone]", this.item.delivery_phone?this.item.delivery_phone:'');
            text = text.replaceAll("[delivery_address]", this.item.delivery_address?this.item.delivery_address:'');
            text = text.replaceAll("[remark]", this.item.remark?this.item.remark:'');
            text = text.replaceAll("[tracking_id]", this.item.tracking_id?this.item.tracking_id:'');
            text = text.replaceAll("[order_code]", this.item.code?this.item.code:'');

            text = text.replaceAll("[customer_email]", this.customer.email?this.customer.email:'');
            text = text.replaceAll("[customer_line]", this.customer.line?this.customer.line:'');
            text = text.replaceAll("[customer_phone]", this.customer.phone?this.customer.phone:'');
            text = text.replaceAll("[customer_name]", this.customer.name?this.customer.name:'');
            text = text.replaceAll("[customer_address]", this.customer.address?this.customer.address:'');
            text = text.replaceAll("[customer_billing_address]", this.customer.billing_address?this.customer.billing_address:'');
            text = text.replaceAll("[customer_billing_phone]", this.customer.billing_phone?this.customer.billing_phone:'');
            text = text.replaceAll("[customer_company_name]", this.customer.company_name?this.customer.company_name:'');
            text = text.replaceAll("[customer_contact]", this.customer.contact?this.customer.contact:'');
            text = text.replaceAll("[customer_credit_line]", this.customer.credit_line?this.customer.credit_line:'');
            text = text.replaceAll("[customer_credit_status]", this.customer.credit_status?this.customer.credit_status:'');
            text = text.replaceAll("[customer_tax_id]", this.customer.tax_id?this.customer.tax_id:'');
            text = text.replaceAll("[customer_customer_id]", this.customer.customer_id?this.customer.customer_id:'');
            text = text.replaceAll("[customer_customer_type]", this.customer.customer_type?this.customer.customer_type:'');
            text = text.replaceAll("[customer_remark]", this.customer.remark?this.customer.remark:'');

            text = text.replaceAll("[delivery_address]", this.item.delivery_address?this.item.delivery_address:'');
            text = text.replaceAll("[delivery_contact]", this.item.delivery_contact?this.item.delivery_contact:'');
            text = text.replaceAll("[delivery_location]", this.item.delivery_location?this.item.delivery_location:'');
            text = text.replaceAll("[delivery_location_id]", this.item.delivery_location_id?this.item.delivery_location_id:'');
            text = text.replaceAll("[delivery_phone]", this.item.delivery_phone?this.item.delivery_phone:'');
            text = text.replaceAll("[price_product]", this.item.price_product?this.item.price_product:'-');
            text = text.replaceAll("[price_shipping]", this.item.price_shipping?this.item.price_shipping:'-');
            text = text.replaceAll("[price_extra]", this.item.price_extra?this.item.price_extra:'-');
            text = text.replaceAll("[cost_product]", this.item.cost_product?this.item.cost_product:'-');
            text = text.replaceAll("[cost_shipping]", this.item.cost_shipping?this.item.cost_shipping:'-');
            text = text.replaceAll("[cost_extra]", this.item.cost_extra?this.item.cost_extra:'-');
            //text = text.replaceAll("[total]", this.item.total?this.item.total:'');
            text = text.replaceAll("[price_refund]", this.item.price_refund?this.item.price_refund:'');

            text = text.replaceAll("[cancel_by]", this.item.cancel_by?this.item.cancel_by:'');
            text = text.replaceAll("[cancel_id]", this.item.cancel_id?this.item.cancel_id:'');
            text = text.replaceAll("[cancel_remark]", this.item.cancel_remark?this.item.cancel_remark:'');

            text = text.replaceAll("[tax_id]", this.item.tax_id?this.item.tax_id:'');
            text = text.replaceAll("[billing_name]", this.item.customer_name?this.item.customer_name:'');
            text = text.replaceAll("[company_name]", this.item.company_name?this.item.company_name:'');
            text = text.replaceAll("[billing_phone]", this.item.customer.phone?this.item.customer.phone:'');
            text = text.replaceAll("[billing_address]", this.item.billing_address?this.item.billing_address:'');

            
            text = text.replaceAll("[donation_name]", this.item.donation_name?this.item.donation_name:'');
            text = text.replaceAll("[donation_data]", this.item.donation_data?this.item.donation_data:'');
            //console.log(this.item);


            const search_tags = text.match(/\[tag:[^\]]*\]/g);
            for(let i in search_tags){
              let tag_key = search_tags[i].replace('[tag:','').replace(']','');
              let tag_value = "-";

              if(this.products[0].tags.length > 0)
              for(const tag of this.products[0].tags){
                if(tag.key == tag_key){
                  tag_value = tag.value;
                }

              }
              text = text.replaceAll(search_tags[i],tag_value);

            }
            text = text.replace(/\[tag:[^\]]*\]/,'555555');

            if(v.name=='template_customer'){
              this.templateCustomer = text;
            }else if(v.name=='template_supplier'){
              this.templateSupplier = text;
            }else if(v.name=='template_bill'){
              this.templateBill = text;
            }
          }
        }
      }
    },
    async mounted() {
      $(document).ready(function () {
        let table = document.querySelector(`#dialog_confirm_order`);
        let button = document.querySelector('#button');
        function selectNode(node){
          let range  =  document.createRange();
          range.selectNodeContents(node)
          let select =  window.getSelection()
          select.removeAllRanges()
          select.addRange(range)
        }

          

      });
    }
  }
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
  .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 20px;
    color: #000000;
    text-align: left;
    text-shadow: initial;
  }
  .carousel-indicators li {
    background-color: #dddd;
  }
  .carousel-indicators {
    bottom: -35px;
  }
  strong {
    font-weight: bold;
  }
</style>
