<template>
  <div>
    <b-modal id="dialog_changecode" size="xl">
      <template #modal-title>
        <h3> ต้องการปรับปรุงรหัสสั่งซื้อใหม่ </h3>
      </template>
      <div>
        <div>{{this.id}}</div>
        <div>- ระบบจะเปลี่ยนตามกฏใหม่</div>
        <div>- ระวังการใช้งาน</div>
        
      </div>
      <template #modal-footer>
        <b-button pill variant="info" @click="changecode()" >เปลี่ยน</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  export default {
    name: 'order-view-dialog-sender',
    data() {
      return {
        id : null
      }
    },
    methods: {
      close () {
        this.$bvModal.hide(`dialog_changecode`);
      },
      setID (id) {
        this.id = id;
      }, 
      async changecode(){
        const result = await this.HttpServices.putData(`/ab_order/changecode/${this.id}`);
        if(result&&result.status.code=="200"){
          this.AlertUtils.alertCallback('สำเร็จ', result.status.message, ()=>{
            this.$bvModal.hide(`dialog_changecode`);
          });
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }

      },
    },
    async mounted() {

    }
  }
</script>

