<template>
  <b-modal id="dialog_order_payment" size="lg">
    <template #modal-title>
      <h3>รอชำระเงิน</h3>
    </template>
    <div>
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(saveHandler)">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-4">
                <label class="form-label">ยอดเงิน</label>
                <base-input name="amount"
                            placeholder="ยอดเงิน"
                            v-model="pay.amount"
                            v-decimal-format
                            inputClasses="form-control">
                </base-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="form-label">การชำระเงิน</label>
                <select class="form-control"
                        v-model="pay.type"
                        placeholder="การชำระเงิน">
                  <option v-for="(type) in type_payments" :key="`payment_type_${type.id}`"
                          :value="type.id">
                    {{type.text}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="form-label">จำนวนเงิน</label>
                <base-input name="total"
                            placeholder="จำนวนเงิน"
                            v-model="pay.total"
                            v-decimal-format
                            inputClasses="form-control">
                </base-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="form-label">เงื่อนไข</label>
                <select class="form-control"
                        v-model="pay.methods"
                        placeholder="เงื่อนไข">
                  <option v-for="(type) in method_payments" :key="`payment_method_${type.id}`"
                          :value="type.id">
                    {{type.text}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="mb-2">
                <label class="form-label">หมายเลขอ้างอิง</label>
                <base-input name="ref"
                            placeholder="หมายเลขอ้างอิง"
                            v-model="pay.ref"
                            inputClasses="form-control" disabled>
                </base-input>
              </div>
            </div>
          </div>
          <button ref="submitButton" style="display:none;">Submit</button>
        </b-form>
      </validation-observer>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="onOutsideSubmit()"><i class="fa fa-check"></i> บันทึก</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
import Constants from '../../../util/constants';
import DateUtils from '../../../util/dateUtils';
import StringUtils from '../../../util/StringUtils';
  export default {
    data() {
      return {
        method_payments: Constants.payment_methods,
        type_payments: Constants.payment_types,
        pay: {
          amount: '',
          type: '',
          methods: '',
          total: '',
          ref: '',
          merge_id: ''
        }
      }
    },
    methods: {
      onOutsideSubmit(){
        this.$refs.submitButton.click();
      },
      async saveHandler () {
        // customer_id, type, ref, amount, methods
        let params = {
          ...this.pay
        }
        const result = await this.HttpServices.postData(`/ab_order/createPayment`, params);
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_order_payment');
          this.$emit('completed', true);
        }else{
          this.AlertUtils.alert("error", result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_order_payment');
      },
      initData(item){
        this.pay.ref = 'trx-'+DateUtils.dateFormat(new Date(), 'ssmmDDYYYYMMHH')+StringUtils.random4Digit();
        this.pay.methods = this.method_payments[0].id;
        this.pay.type = this.type_payments[0].id;
        this.pay.order_id = item.id;
        this.pay.customer_id = item.customer_id;
        this.pay.total = item.total;
      }
    }
  }
</script>
