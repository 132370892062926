<template>
  <div>
    <b-modal id="dialog_order_refund" size="lg">
      <template #modal-title>
        <h3><i class="fas fa-hand-holding-usd"></i> การคืนเงิน</h3>
      </template>
      <div>
        <!-- Add -->
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form @submit.prevent="handleSubmit(saveHandler)">
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right">เลขสั่งซื้อ</label>
              <div class="col-sm-8">
                <base-input name="orderId" placeholder="เลขสั่งซื้อ" v-model="dataRow.orderId" disabled ></base-input>
              </div>
            </div>
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right">หมายเลขปัญหา</label>
              <div class="col-sm-8">
                <select class="form-control"  v-model="item.issue_id" @change="onIssueChange">
                  <option v-for="option in cbbOptions.issues" :value="option.id" >{{option.id + ' : ' + option.issue_type}}</option>
                </select>
              </div>
            </div>
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right">วันที่สั่ง</label>
              <div class="col-sm-2">
                <base-input  name="orderDate" placeholder="วันที่สั่ง" v-model="dataRow.date_order" readonly></base-input>
              </div>
              <label class="col-sm-2 col-form-label label_algin_right">ชื่อผู้สั่ง</label>
              <div class="col-sm-4">
                <base-input name="orderName" placeholder="ชื่อผู้สั่ง" v-model="dataRow.customer_name" readonly></base-input>
              </div>
            </div>
            <div class="mb-2 row">
              
            </div>
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right">ร้านค้า</label>
              <div class="col-sm-8">
                <base-input
                      name="storeName"
                      placeholder="ร้านค้า"
                      v-model="dataRow.storeName"
                      disabled
                    ></base-input>
              </div>
            </div>
            <div class="mb-4 row">
                <label class="col-sm-4 col-form-label label_algin_right">สถานะการคืนเงิน</label>
                <div class="col-sm-8">
                  <select class="form-control" v-model="item.status_refund" >
                    <option v-for="(data, index) in cbbOptions.refund_status" :key="index" :value="data.id">{{data.text}}</option>
                  </select>
                </div>
            </div>
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right">สาเหตุโอนเงินคืน</label>
              <div class="col-sm-8">
                <select class="form-control"  v-model="item.reason_id">
                  <option v-for="option in cbbOptions.refund_reason" :value="option.id" >{{option.id + ' : ' + option.text}}</option>
                </select>
              </div>
            </div>
            <div class="m-4">
              <b-tabs content-class="mt-3">
                <b-tab title="ข้อมูลการโอน">
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ชื่อผู้โอน</label>
                    <div class="col-sm-8">
                      <base-input placeholder="ชื่อผู้โอน" v-model="payment.customer" readonly></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ธนาคาร</label>
                    <div class="col-sm-8">
                      <select class="form-control" v-model="payment.type" readonly>
                        <option v-for="data in cbbOptions.payment_types" :key="data.id" :value="data.id" > {{ data.text }} </option>
                      </select>
                    </div>
                  </div>
                  <div class="mb-4 row">
                  <label class="col-sm-4 col-form-label label_algin_right">วันที่</label>
                  <div class="col-sm-5">
                    <base-input placeholder="ชื่อผู้โอน" v-model="payment.upload_date" readonly></base-input> </div>
                    <label class="col-sm-1 col-form-label label_algin_right">เวลา</label>
                    <div class="col-sm-2">
                      <base-input placeholder="ชื่อผู้โอน" v-model="payment.upload_time" readonly></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ยอดที่โอนเข้า</label>
                    <div class="col-sm-8">
                      <base-input placeholder="ชื่อผู้โอน" v-model="payment.amount" readonly></base-input>
                    </div>
                  </div>
                  
                  
                </b-tab>
                <b-tab title="การโอนคืน">
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ยอดโอนคืน</label>
                    <div class="col-sm-8">
                      <base-input  placeholder="ยอดโอนคืน" v-model="item.amount"></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">วันที่โอนคืน</label>
                    <div class="col-sm-8">
                      <date-picker :format="'DD/MM/YYYY'" v-model="item.refund" class="w-100" input-class="form-control" placeholder="วันที่โอนคืน" :append-to-body="false" :popup-style="{ left: 0 }"></date-picker>
                    </div>
                  </div><div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ธนาคาร</label>
                    <div class="col-sm-8">
                      <select class="form-control" v-model="item.customer_bank">
                        <option v-for="data in cbbOptions.payment_types" :key="data.id" :value="data.text" > {{ data.text }} </option>
                      </select>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">เลขบัญชี</label>
                    <div class="col-sm-8">
                      <base-input placeholder="เลขบัญชี" v-model="item.customer_bank_number"></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ชื่อบัญชี</label>
                    <div class="col-sm-8">
                      <base-input placeholder="ชื่อบัญชี" v-model="item.customer_bank_name"></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">หมายเหตุ</label>
                    <div class="col-sm-8">
                      <b-form-textarea placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="อัพเซล">
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">เริ่มต้น</label>
                    <div class="col-sm-8">
                      <base-input name="trafebckupsstrt" placeholder="เริ่มต้น" v-model="dataRow.transferBack.upsaleStart"></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ราคาอัพเซล</label>
                    <div class="col-sm-8">
                      <base-input name="trafebckupsprce" placeholder="ราคาอัพเซล" v-model="dataRow.transferBack.upsalePrice"></base-input>
                    </div>
                  </div>
                  
                  <div class="mb-4 row" v-if="item.status_refund == 1">
                    <label class="col-sm-4 col-form-label label_algin_right">จำนวนเงินที่คืน</label>
                    <div class="col-sm-8">
                      <base-input name="price_refund" placeholder="จำนวนเงินที่คืน" v-model="item.price_refund" :rules="{required: item.status_refund==1}" v-decimal-format></base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">เหตุผล</label>
                    <div class="col-sm-8">
                      <b-form-textarea  name="reason" placeholder="เหตุผล" v-model="item.reason" rows="3" max-rows="5"></b-form-textarea>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="จัดการปัญหา">
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ที่มาปัญหา</label>
                    <div class="col-sm-8">
                      <select class="form-control" :rules="{ required: true }" v-model="issue.cause_id" readonly>
                        <option v-for="cause in cbbOptions.cause" :key="`cbx_cause_${cause.id}`" :value="cause.id" >{{ cause.text }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">จากผู้ใช้งาน</label>
                    <div class="col-sm-8"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.user" readonly></base-input></div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">จากลูกค้า</label>
                    <div class="col-sm-8"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.customer" readonly></base-input></div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">จากซัพ</label>
                    <div class="col-sm-8"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.supplier" readonly></base-input></div>
                  </div>

                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ส่วนลดให้ลูกค้า</label>
                    <div class="col-sm-4"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.comp_customer_discount" readonly></base-input></div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">เพิ่ม/ลดเงินซัพ</label>
                    <div class="col-sm-4"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.comp_supplier_discount" readonly></base-input></div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ความเสียหายอื่น</label>
                    <div class="col-sm-4"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.comp_other" readonly></base-input></div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ยอดรวม</label>
                    <div class="col-sm-4"><base-input placeholder="ไม่มีข้อมูล" v-model="issue.comp_total" readonly></base-input></div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            
            <button ref="submitButton" style="display:none;">Submit</button>
          </b-form>
        </validation-observer>

      </div>
      <template #modal-footer>
        <b-button variant="primary" class="style_btn" @click="saveHandler()"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Constants from '../../../util/constants';
// Add
import DateUtils from "../../../util/dateUtils";

export default {
  name: 'order-view-dialog-refund',
  data () {
    return {
      cbbOptions : {
        cause : Constants.issue_causes,
        payment_status: Constants.payment_status,
        payment_types: Constants.refund_bank,
        refund_reason: Constants.refund,
        refund_status: Constants.refund_status,
        issues : [],
        users : []
      },
      payment: {},
      issue: {},
      item: {
        order_id: null,
        issue_id : null,
        refund_by : null,
        status_refund: 0,
        refund: '',
        amount: 0,
        reason_id : 1,
        customer_id: '',
        customer_bank: '',
        customer_bank_number: '',
        customer_bank_name: '',
        remark: '',
      },
      refunds: Constants.refund_status
    }
  },
  // Add
  props: ["dataRow"],
  // Old
  methods: {
    onOutsideSubmit(){
      this.$refs.submitButton.click();
    },
    close () {
      this.$bvModal.hide('dialog_order_refund');
    },
    async saveHandler () {
      const params = {
        status: this.item.status_refund,
        price: this.item.status_refund==1?this.item.price_refund:null,
        remark: this.item.reason
      };
      const result = await this.HttpServices.postData(`/ab_payment/refund`, this.item);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
        this.$bvModal.hide('dialog_order_refund');
        this.$emit("successFuction", "");
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    async onIssueChange(event){
      const result = await this.HttpServices.getData(`/ab_issue/get_info/${this.item.issue_id}`);
      this.issue = result.data;
      this.item.reason_id = result.data.issue_type_id;
      
    },
    async getIssueType() {
      this.cbbOptions.refund_reason = await this.HttpServices.getMasterData(
        `/master/getIssueTypes`
      );
    },
    async initRefund(orderId){
      this.item.order_id = orderId;
      this.item.customer_id = this.dataRow.customer_id;
      const result = await this.HttpServices.getData(`/ab_issue/listbyorder/${this.item.order_id}`);
      this.cbbOptions.issues = result.data;

      const rstPayment = await this.HttpServices.getData(`/ab_payment/getItemByOrderId/${this.item.order_id}`);
      this.payment = rstPayment.data;
      this.item.customer_bank = this.payment.customer_bank;

      this.SessionStorageUtils.decodeUser().then(user => {
        this.item.refund_by= user.id;
      });

      this.getIssueType();

      /*
      if(dataRow.issues){
        this.item.issue_id = dataRow.issues.id
        this.issue = dataRow.issue;
        await onIssueChange();
      };
      */


    }
  }
}
</script>
