<template>
  <div>
    <b-modal id="dialog_sender" size="xl">
      <template #modal-title>
        <h3> ส่ง E-Mail </h3>
      </template>
      <div>
        {{this.id}}
      </div>
      <template #modal-footer>
        <b-button pill variant="info" @click="sendmail('A')" >Email A</b-button>
        <b-button pill variant="info" @click="sendmail('B')" >Email B</b-button>
        <b-button pill variant="info" @click="sendmail('C')" >Email C</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  export default {
    name: 'order-view-dialog-sender',
    data() {
      return {
        id : null
      }
    },
    methods: {
      close () {
        this.$bvModal.hide(`dialog_sender`);
      },
      setID (id) {
        this.id = id;
      }, 
      async sendmail(type){
        const result = await this.HttpServices.putData(`/ab_order/sendmail/${this.id}`,{"type":type});
        if(result&&result.status.code=="200"){
          this.AlertUtils.alertCallback('success', `ส่งสำเร็จ`, ()=>{
            
            
          });
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }

      },
    },
    async mounted() {

    }
  }
</script>

