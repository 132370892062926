<template>
  <div>
    <b-modal id="dialog_order_inspection" size="lg">
      <template #modal-title>
        <h3>ตรวจสอบออเดอร์แล้ว</h3>
      </template>
      <div>
        <div class="mb-2 row">
          <label class="col-sm-4 col-form-label label_algin_right"
            >หมายเหตุ</label
          >
          <div class="col-sm-8">
            <b-form-textarea
              name="remark_not_check"
              placeholder="หมายเหตุ"
              v-model="item.remark_not_check"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saveHandler()" class="style_btn"
          ><i class="fa fa-check"></i> ตกลง</b-button
        >
        <b-button variant="secondary" @click="close()"
          ><i class="fa fa-times"></i> ยกเลิก</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "order-view-dialog-inspection",
  props: ["item"],
  methods: {
    async saveHandler() {
      const params = { status: 2, remark: this.item.remark_not_check };
      const result = await this.HttpServices.putData(
        `/ab_order/updateInspection/${this.item.id}`,
        params
      );
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", "ตรวจสอบออเดอร์แล้วสำเร็จ");
        this.$emit("inspect-completed", 1);
        this.$bvModal.hide(`dialog_order_inspection`);
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close() {
      this.$bvModal.hide(`dialog_order_inspection`);
    }
  }
};
</script>
